import {MainTable} from "../../Components/MainTable/MainTable";
import {useEffect, useState} from "react";
import axios from "axios";
import {useAuthHeader} from "react-auth-kit";
import {message} from "antd";
import {
    CodeSandboxOutlined,
    CloseCircleOutlined,
    InboxOutlined,
    PhoneOutlined,
} from '@ant-design/icons';
import {CategoryAdd} from "./CategoryAdd";
import {CategoryEdit} from "./CategoryEdit";

export function Category() {
    let [data, setData] = useState([])
    let [rend, setRend] = useState(false)
    let [editData, setEditData] = useState({'id': null});
    let [stat, setStat] = useState([])

    let url = process.env.REACT_APP_URL + '/wcategorys';
    const authHeader = useAuthHeader();

    useEffect(() => {
        axios(url, {
            method: 'GET',
            headers: {
                'Authorization': authHeader(),
                'Content-type': 'application/json'
            }
        }).then(data => {
            setData(data.data)
        })
    }, [rend]);


    const handleDel = (e, rec) => {
        let delurl = process.env.REACT_APP_URL + '/wcatdel/' + rec.id;
        axios.get(delurl, {
            headers: {
                'Authorization': authHeader(),
                'Content-type': 'application/json'
            },
        }).then(resData => {
            message.success(`Ma'lumot muvaffaqiyatli O'chirildi`);
            setRend(!rend)
        })
    }
    // status

    const columns = [
        {
            title: 'Название',
            dataIndex: 'name',
            key: 'name',

        },
        {
            title: 'Подробнее',
            dataIndex: 'desc',
            key: 'desc',
        },
    ];
    let onEdit = (rec) => {
        setEditData(rec)
        setRend(!rend)
    }
    return (
        <>
            <MainTable
                defkey={'id'}
                pdata={data}
                pcolumns={columns}
                add={<CategoryAdd adder={setData} dt={data}/>}
                onDelete={handleDel}
                setEd={setEditData}
                edit={<CategoryEdit adder={setRend} rend={rend} dt={data} iv={editData}/>}
                onEdit={onEdit}
                statData={stat}
                pageTitle={'Категории'}
            />
        </>
    )
}