import {MainTable} from "../../Components/MainTable/MainTable";
import {useEffect, useState} from "react";
import axios from "axios";
import {useAuthHeader} from "react-auth-kit";
import {message} from "antd";
import {StockAdd} from "./StockAdd";
import {StockEdit} from "./StockEdit";
import {
    CodeSandboxOutlined,
    GoldOutlined,
    DollarOutlined,
    InboxOutlined,
} from '@ant-design/icons';

export function Stock() {
    let [data, setData] = useState([])
    let [rend, setRend] = useState(false)
    let [editData, setEditData] = useState({'id': null});
    let [stat, setStat] = useState([])

    let url = process.env.REACT_APP_URL + '/witems';
    let urlstat = process.env.REACT_APP_URL + '/witstat';
    const authHeader = useAuthHeader();

    useEffect(() => {
        axios(url, {
            method: 'GET',
            headers: {
                'Authorization': authHeader(),
                'Content-type': 'application/json'
            }
        }).then(data => {
            setData(data.data)

        })
    }, [rend]);

    useEffect(() => {
        axios(urlstat, {
            method: 'GET',
            headers: {
                'Authorization': authHeader(),
                'Content-type': 'application/json'
            }
        }).then(data => {
            let d = [
                {
                    id: 1,
                    title: 'Продукты',
                    value: data.data.prodcount,
                    valueColor: 'blue',
                    sufix: 'типа',
                    icon: <InboxOutlined/>
                },
                {
                    id: 2,
                    title: 'Категории',
                    value: data.data.catCount,
                    valueColor: 'blue',
                    sufix: '',
                    icon: <CodeSandboxOutlined/>
                },
                {
                    id: 3,
                    title: 'Общая количество',
                    value: data.data.stocksumm,
                    valueColor: 'blue',
                    sufix: 'штук',
                    icon: <GoldOutlined/>
                },
                {
                    id: 4,
                    title: 'Общая сумма',
                    value: data.data.pricesumm,
                    valueColor: 'blue',
                    sufix: '$',
                    icon: <DollarOutlined/>
                },
            ]
            setStat(d)
        })
    }, [rend]);


    const handleDel = (e, rec) => {
        let delurl = process.env.REACT_APP_URL + '/witdel/' + rec.id;
        axios.get(delurl, {
            headers: {
                'Authorization': authHeader(),
                'Content-type': 'application/json'
            },
        }).then(resData => {
            message.success(`Удалено`);
            setRend(!rend)
        })
    }
    const columns = [
        {
            title: 'Rasmi',
            dataIndex: 'img',
            key: 'img',
            render: (_, item) => <img style={{height: '40px'}} src={item.img} alt="Tovar surati"/>
        },
        {
            title: 'Название',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Марка',
            dataIndex: 'marka',
            key: 'marka',
        },
        // {
        //     title: 'Цена',
        //     dataIndex: 'price',
        //     key: 'price',
        // },
        {
            title: 'Цена на USD',
            dataIndex: 'priceusd',
            key: 'priceusd',
        },
        {
            title: 'Кол-во',
            dataIndex: 'stock',
            key: 'stock',
        },
        {
            title: 'Размерь',
            dataIndex: 'razmer',
            key: 'razmer',
        },
        {
            title: 'Толшина',
            dataIndex: 'qalin',
            key: 'qalin',
        },
        {
            title: 'Описание',
            dataIndex: 'desc',
            key: 'desc',
        },
        {
            title: 'Категория',
            dataIndex: 'catname',
            key: 'catname',
        },
    ];
    let onEdit = (rec) => {
        setEditData(rec)
        setRend(!rend)
    }

    return (
        <>
            <div style={{height: '85vh', overflow: 'auto'}}>


                <MainTable
                    defkey={'id'}
                    pdata={data}
                    pcolumns={columns}
                    add={<StockAdd adder={setData} dt={data}/>}
                    onDelete={handleDel}
                    setEd={setEditData}
                    edit={<StockEdit adder={setRend} rend={rend} dt={data} iv={editData}/>}
                    onEdit={onEdit}
                    statData={stat}
                    pageTitle={'Товары и Инвентаризация'}
                />
            </div>
        </>
    )
}