export function ProdModal({ close, mdata, cat, val, kurs }) {


    const otr = () => {
        return (
            <>
                <p className="card-open__model">{mdata.marka}</p>
                <p className="card-open__size card-open--disk">{mdata.razmer}</p>
            </>
        )
    }

    const nd = () => {
        return (
            <>
                 <p className="card-open__size card-open--disk">Диаметр: {mdata.razmer}</p>
                 <p className="card-open__size card-open--disk">Толшина: {mdata.qalin}</p>
            </>
        )
    }

    const all = () => {
        return (
            <>
                <p className="card-open__size card-open--disk">Марка: {mdata.marka}</p>
                <p className="card-open__size card-open--disk">Размер: {mdata.razmer}</p>
                <p className="card-open__size card-open--disk">Толшина: {mdata.qalin}</p>
            </>
        )
    }

    return (
        <>
            <div className="backdrop__wrapper">
                <div className="backdrop" onClick={close}></div>
                <div className="card-open__content">
                    <img onClick={close} className={'card-open__close-icon'} width={'30px'} src="/assets/media/close.svg" alt="close" />
                    <img className="card-open__img" src={mdata.img} alt="img" />
                    <div className="card-open__text-block">
                        <p className="card-open__title">{mdata.name}</p>
                        {(cat.id === 4) ? otr() : (cat.id === 1) ? '' : (cat.id === 5) ? nd() : all()}
                        <p style={{ color: 'red', fontWeight: 700 }} className="card-open__size card-open--disk">В наличие: {mdata.stock} шт</p>
                        <p style={{color: '#3b3939'}} className="card-open__price">{(val) ? Math.floor(mdata.priceusd * kurs) + ' сум' : mdata.priceusd + ' $'}</p>
                    </div>
                </div>
            </div>
        </>
    )
}