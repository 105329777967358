import {Button, Col, Form, Input, InputNumber, Row} from "antd";
import {Select, Table} from "antd/lib";
import {useAuthHeader} from "react-auth-kit";
import {useEffect, useState} from "react";
import axios from "axios";
import {DeleteOutlined} from "@ant-design/icons";
import css from './Pos.module.css'

export function Pos() {
    const [data, setData] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [dataProd, setDataProd] = useState([]);
    let [inprod, setInprod] = useState([])
    let [sendData, setSendData] = useState([])
    let [kontr, setKontr] = useState([])
    let [hist, setHist] = useState([])
    let url = process.env.REACT_APP_URL + '/witems';
    let wurl = process.env.REACT_APP_URL + '/wsupliers';
    let inurl = process.env.REACT_APP_URL + '/winstock';
    const authHeader = useAuthHeader();

    const c = [
        {
            title: 'Фактура',
            dataIndex: 'invoice_number',
            key: 'invoice_number',
        },
        {
            title: 'Дата',
            dataIndex: 'date',
            key: 'date',
        },
        {
            title: 'Покупатель',
            dataIndex: 'supname',
            key: 'supname',
        },
        {
            title: 'Постащик',
            dataIndex: 'deliver_info',
            key: 'deliver_info',
        },
    ];

    let inc = [
        {
            title: 'Название',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Кол-во',
            dataIndex: 'count',
            key: 'count',
        },
        {
            title: 'Цена',
            dataIndex: 'price',
            key: 'price',
        },
    ]


    useEffect(() => {
        axios(url, {
            method: 'GET',
            headers: {
                'Authorization': authHeader(),
                'Content-type': 'application/json'
            }
        }).then(data => {
            let findProdProps = data.data.map(item => {
                return {
                    value: item.id,
                    label: item.name + ' | Марка: ' + item.marka + ' | Размер: ' + item.razmer,
                }
            })
            setData(findProdProps)
            setDataProd(data.data)
        })
    }, []);

    useEffect(() => {
        axios(wurl, {
            method: 'GET',
            headers: {
                'Authorization': authHeader(),
                'Content-type': 'application/json'
            }
        }).then(data => {
            let findProdProps = data.data.map(item => {
                return {
                    value: item.id,
                    label: item.name + ' | Марка: ' + item.marka + ' | Размер: ' + item.razmer,
                }
            })
            setKontr(data.data)
        })
    }, []);

    useEffect(() => {
        axios(inurl, {
            method: 'GET',
            headers: {
                'Authorization': authHeader(),
                'Content-type': 'application/json'
            }
        }).then(data => {
            setHist(data.data)
        })
    }, []);
    const onChange = (value) => {
        let selected = dataProd.find(item => item.id === value)
        let Itemname = dataProd.find(item => item.id === value)
        let sData = {
            name: Itemname.name,
            marka: Itemname.marka,
            razmer: Itemname.razmer,
            warehouse_items_id: value,
            count: 1,
            price: Itemname.price,
        }
        sData.summ = sData.count * sData.price
        setSendData([sData, ...sendData])
        setInprod([selected, ...inprod])
    };

    const handleProd = (value) => {
        let f = sendData.find(item => item.warehouse_items_id === value.id)
        if (f) {
            let rewrite = sendData.map(item => {
                if (item.warehouse_items_id === value.id) {
                    item.count = item.count + 1
                    return item;
                } else {
                    return item
                }
            })
            setSendData(rewrite)
        } else {
            let sData = {
                name: value.name,
                warehouse_items_id: value.id,
                count: 1,
                price: value.price,
            }
            sData.summ = sData.count * sData.price
            setSendData([sData, ...sendData])
        }
    };
    const onSearch = (value) => {

    };
    const handleCheckprice = (value, i) => {
        i.price = value
        i.summ = i.price * i.count
        let sData = sendData.filter(item => item.warehouse_items_id !== i.warehouse_items_id)
        setSendData([i, ...sData])
    }
    const handleCheckCount = (value, i) => {
        i.count = value
        i.summ = i.price * i.count
        let sData = sendData.filter(item => item.warehouse_items_id !== i.warehouse_items_id)
        setSendData([i, ...sData])
    }
    const handleDelete = (e, rec) => {
        let delData = inprod.filter(item => item.id !== rec.id)
        let sData = sendData.filter(item => item.warehouse_items_id !== rec.warehouse_items_id)
        setInprod(delData)
        setSendData(sData)
    }
    const columns = [
        {
            title: 'Название',
            dataIndex: 'name',
            key: 'name',
            render: (_, rec) => {
                return <p>{rec.name} <br/> {rec.marka} <br/> {rec.razmer}</p>
            }
        },
        {
            title: 'Цена',
            dataIndex: 'price',
            key: 'price',
            render: (_, i) => (
                <>
                    <InputNumber
                        value={i.price}
                        onChange={(e) => handleCheckprice(e, i)}/>
                </>
            ),
        },
        {
            title: 'Кол-во',
            dataIndex: 'count',
            key: 'count',
            width: 200,
            render: (_, i) => (
                <>
                    <InputNumber
                        value={i.count}
                        onChange={(e) => handleCheckCount(e, i)}
                    />
                </>
            ),
        },
        {
            title: 'Сумма',
            dataIndex: 'summ',
            key: 'summ',
        },
        {
            title: 'Инструменты',
            key: 'action',
            render: (_, rec) => (
                <Button onClick={(e) => handleDelete(e, rec)} type="primary" shape="circle" icon={<DeleteOutlined/>}/>
            ),
        },
    ];
    const onFinish = (values) => {
        values.items = sendData;
        axios.post(process.env.REACT_APP_URL + '/pos', values, {
            headers: {
                'Authorization': authHeader(),
                'Content-type': 'application/json'
            },
        }).then(resData => {
            let result = resData.data
        })

    };
    const onFinishFailed = (errorInfo) => {

    };
    const [open, setOpen] = useState(false);

    const showDrawer = () => {
        setOpen(true);
    };

    const onClose = () => {
        setOpen(false);
    };

    return (
        <>

            <Row gutter={25}>
                <Col span={18}>
                    <Select
                        showSearch
                        size={'large'}
                        style={{width: '100%'}}
                        placeholder="Maxsulot nomi, Shtrix kodi yoki artiklini kiriting"
                        optionFilterProp="children"
                        onChange={onChange}
                        onSearch={onSearch}
                        filterOption={(input, option) =>
                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                        }
                        options={data}
                    />
                    <Table rowKey={'warehouse_items_id'} columns={columns} dataSource={sendData}
                           style={{marginTop: '15px'}}/>
                </Col>
                <Col span={6}>
                    <div className={css.prodlist} style={{position: 'relative'}}>
                        <div>
                            <h5>Итог: {sendData.reduce((a, b) => a + b.summ, 0)}</h5>
                            <Form
                                name="basic"
                                labelCol={{
                                    span: 24,
                                }}
                                wrapperCol={{
                                    span: 24,
                                }}

                                onFinish={onFinish}
                                onFinishFailed={onFinishFailed}
                                autoComplete="off"
                            >
                                <Form.Item
                                    label="№ фактуры"
                                    name="invoice_number"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Заполните!',
                                        },
                                    ]}
                                >
                                    <Input/>
                                </Form.Item>
                                <Form.Item
                                    label="status"
                                    hidden={true}
                                    name="status"
                                    initialValue={'Продано'}
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input your username!',
                                        },
                                    ]}
                                >
                                    <Input/>
                                </Form.Item>
                                <Form.Item
                                    label="Покупатель"
                                    name="deliver_info"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input your username!',
                                        },
                                    ]}
                                >
                                    <Input/>
                                </Form.Item>
                                <Form.Item
                                    label="Дата"
                                    name="date"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input your username!',
                                        },
                                    ]}
                                >
                                    <Input type={'date'}/>
                                </Form.Item>
                                <Form.Item
                                    wrapperCol={{
                                        offset: 0,
                                        span: 24,
                                    }}
                                >
                                    <Button type="primary" htmlType="submit">
                                        Продать
                                    </Button>
                                </Form.Item>
                            </Form>
                        </div>

                    </div>
                </Col>
            </Row>

        </>
    )
}