export function Intro() {
    return (
        <>
            <div className="bg-vid">
                <ul className="media-link__list">
                    <li className="media-link__item">
                        <img src={'/assets/media/instagram.svg'}/>
                        <a className="media-link media-link__instagram" href="https://instagram.com/hagen_by_rst?igshid=ZDdkNTZiNTM=">Instagram</a>
                    </li>
                    <li className="media-link__item">
                        <img src={'/assets/media/telegram.svg'}/>
                        <a className="media-link media-link__telegram" target="_blank"
                           href="https://t.me/rimaxstelltrade">Telegram</a>
                    </li>
                    <li className="media-link__item">
                        <img src={'/assets/media/mail.svg'}/>
                        <a className="media-link media-link__gmail" target="_blank"
                           href="mailto:rimax.steel.trade777@gmail.com">rimax.steel.trade777@gmail.com</a>
                    </li>
                    <li className="media-link__item">
                        <img src={'/assets/media/phone.svg'}/>
                        <a className="media-link media-link__number" href="tel:+998909116606">(+99890) 911-66-06</a>
                        <a className="media-link media-link__number" href="tel:+998993046606">(+99899) 304-66-06</a>
                    </li>
                </ul>
                <video autoPlay={true} loop={true} muted={true} id="myVideo">
                    <source src="/assets/media/Hagen.mp4" type="video/mp4"/>
                </video>
            </div>
        </>
    )
}