import {useSignOut} from "react-auth-kit";
import {
    MenuFoldOutlined,
    MenuUnfoldOutlined,
    BarChartOutlined,
    ShoppingCartOutlined,
    SwapOutlined,
    PoweroffOutlined,
    InboxOutlined,
    UserDeleteOutlined,
    UserAddOutlined,
} from '@ant-design/icons';
import {Layout, Menu, theme, Button} from 'antd';
import React, {useState} from 'react';
import {NavLink, Route, Routes} from "react-router-dom";
import logo from './../../logo.svg'
import {Stock} from "../../Pages/Stock/Stock";
import {Pos} from "../../Pages/Pos/Pos";
import {Category} from "../../Pages/Categorys/Category";
import {Instock} from "../../Pages/Instock/Instock";
import {Valkurs} from "./Valkurs";
import {Valyuta} from "../../Pages/Valyuta";

const {Header, Sider, Content} = Layout;


const MainLayout = () => {

    const [collapsed, setCollapsed] = useState(false);
    const {
        token: {colorBgContainer},
    } = theme.useToken();

    const signOut = useSignOut()

    return (
        <Layout style={{height: '100vh'}}>
            <Sider trigger={null} collapsible collapsed={collapsed}>
                <div className="logo" style={{display: 'flex', padding: '10px', alignItems: 'center'}}>
                    <img style={{height: '40px'}} src={logo} alt={'logo'}/>
                    <span style={{fontSize: '18px', fontWeight: '600', color: 'lightblue'}}>STEBIZ</span>
                </div>
                <Menu
                    theme="dark"
                    mode="inline"
                    defaultSelectedKeys={['1']}
                    items={[
                        {
                            key: '1',
                            icon: <ShoppingCartOutlined/>,
                            label: <NavLink to="/sklad">Продажа</NavLink>,
                        },
                        {
                            key: '2',
                            icon: <SwapOutlined/>,
                            label: <NavLink to="/sklad/instock">Прием</NavLink>,
                        },

                        {
                            key: '4',
                            icon: <InboxOutlined/>,
                            label: <NavLink to="/sklad/stock">Товары</NavLink>,
                        },
                        {
                            key: '5',
                            icon: <UserAddOutlined/>,
                            label: <NavLink to="/sklad/category">Категории</NavLink>,
                        },
                        // {
                        //     key: '6',
                        //     icon: <SwapOutlined/>,
                        //     label: <NavLink to="/sklad/valyuta">Курс валют</NavLink>,
                        // },
                    ]}
                />
            </Sider>
            <Layout className="site-layout" style={{backgroundColor: 'rgb(240, 242, 245)'}}>
                <Header
                    style={{
                        padding: '5px 25px',
                        background: colorBgContainer,
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    }}
                >
                    {React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
                        className: 'trigger',
                        onClick: () => setCollapsed(!collapsed),
                    })}
                    <div className="right">
                        <Valkurs/>
                        <Button
                            style={{marginLeft: '15px'}}
                            type="primary"
                            icon={<PoweroffOutlined/>}
                            onClick={signOut}
                            danger
                        />
                    </div>
                </Header>
                <Content
                    style={{
                        // margin: '24px 16px',
                        padding: 24,
                        minHeight: 280,
                        background: 'rgb(240, 242, 245)',
                    }}
                >

                    <Routes>
                        <Route path={'/'} element={<Pos/>}/>
                        <Route path={'/instock'} element={<Instock/>}/>
                        <Route path={'/stock'} element={<Stock/>}/>
                        <Route path={'/category'} element={<Category/>}/>
                        {/*<Route path={'/valyuta'} element={<Valyuta/>}/>*/}
                    </Routes>
                </Content>
                <div style={{textAlign: "center", fontWeight: '700'}}>
                    <a href={"https://cyberarea.uz"}>CYBER AREA UZB | STEBIZ</a>
                </div>
            </Layout>
        </Layout>
    );
};

export default MainLayout;