import {Button, Card, Col, Form, Input, InputNumber, Row} from "antd";
import { Drawer, Select, Table} from "antd/lib";
import {useAuthHeader} from "react-auth-kit";
import {useEffect, useState} from "react";
import axios from "axios";
import {DeleteOutlined} from "@ant-design/icons";
import TextArea from "antd/lib/input/TextArea";
import css from './Instock.module.css'



export function Instock() {
    const [data, setData] = useState([]);
    const [dataProd, setDataProd] = useState([]);
    let [inprod, setInprod] = useState([])
    let [sendData, setSendData] = useState([])
    let [kontr, setKontr] = useState([])
    let [hist, setHist] = useState([])
    let url = process.env.REACT_APP_URL + '/witems';
    let wurl = process.env.REACT_APP_URL + '/wsupliers';
    let inurl = process.env.REACT_APP_URL + '/winstock';
    const authHeader = useAuthHeader();

    const c = [
        {
            title: '№ Фактура',
            dataIndex: 'invoice_number',
            key: 'invoice_number',
        },
        {
            title: 'Время',
            dataIndex: 'date',
            key: 'date',
        },
        {
            title: 'Контрагент',
            dataIndex: 'supname',
            key: 'supname',
        },
        {
            title: 'Поставщик',
            dataIndex: 'deliver_info',
            key: 'deliver_info',
        },
        {
            title: 'Статус оплаты',
            dataIndex: 'payed',
            key: 'payed',
        },
        {
            title: 'Статус получение',
            dataIndex: 'status',
            key: 'status',
        },
    ];

    let inc = [
        {
            title: 'Название',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Количество',
            dataIndex: 'count',
            key: 'count',
        },
        {
            title: 'Цена',
            dataIndex: 'price',
            key: 'price',
        },
    ]


    useEffect(() => {
        axios(url, {
            method: 'GET',
            headers: {
                'Authorization': authHeader(),
                'Content-type': 'application/json'
            }
        }).then(data => {
            let findProdProps = data.data.map(item => {
                return {
                    value: item.id,
                    label: item.name + ' | Марка: ' + item.marka + ' | Размер: ' + item.razmer,
                }
            })
            setData(findProdProps)
            setDataProd(data.data)
        })
    }, []);

    useEffect(() => {
        axios(wurl, {
            method: 'GET',
            headers: {
                'Authorization': authHeader(),
                'Content-type': 'application/json'
            }
        }).then(data => {
            let findProdProps = data.data.map(item => {
                return {
                    value: item.id,
                    label: item.name + ' | Марка: ' + item.marka + ' | Размер: ' + item.razmer,
                }
            })
            setKontr(data.data)
        })
    }, []);

    useEffect(() => {
        axios(inurl, {
            method: 'GET',
            headers: {
                'Authorization': authHeader(),
                'Content-type': 'application/json'
            }
        }).then(data => {
            setHist(data.data)
        })
    }, []);
    const onChange = (value) => {
        let selected = dataProd.find(item => item.id === value)
        let Itemname = dataProd.find(item => item.id === value)
        let sData = {
            name: Itemname.name,
            marka: Itemname.marka,
            razmer: Itemname.razmer,
            warehouse_items_id: value,
            count: 1,
            price: Itemname.price,
        }
        sData.summ = sData.count * sData.price
        setSendData([sData, ...sendData])
        setInprod([selected, ...inprod])
    };
    const onSearch = (value) => {

    };
    const handleCheckprice = (value, i) => {
        i.price = value
        i.summ = i.price * i.count
        let sData = sendData.filter(item => item.warehouse_items_id !== i.warehouse_items_id)
        setSendData([i, ...sData])
    }
    const handleCheckCount = (value, i) => {
        i.count = value
        i.summ = i.price * i.count
        let sData = sendData.filter(item => item.warehouse_items_id !== i.warehouse_items_id)
        setSendData([i, ...sData])
    }
    const handleDelete = (e, rec) => {
        let delData = inprod.filter(item => item.id !== rec.id)
        let sData = sendData.filter(item => item.warehouse_items_id !== rec.id)
        setInprod(delData)
        setSendData(sData)
    }
    const columns = [
        {
            title: 'Продукт',
            dataIndex: 'name',
            key: 'name',
            render: (_, rec)=>{
                return <p>{rec.name} <br/> {rec.marka} <br/> {rec.razmer}</p>
            }
        },
        {
            title: 'Цена',
            dataIndex: 'price',
            key: 'price',
            render: (_, i) => (
                <>
                    <InputNumber
                        defaultValue={i.price}
                        onChange={(e) => handleCheckprice(e, i)}/>
                </>
            ),
        },
        {
            title: 'Количество',
            dataIndex: 'count',
            key: 'count',
            width: 200,
            render: (_, i) => (
                <>
                    <InputNumber
                        defaultValue={i.count}
                        onChange={(e) => handleCheckCount(e, i)}
                    />
                </>
            ),
        },
        {
            title: 'Сумма',
            dataIndex: 'summ',
            key: 'summ',
        },
        {
            title: 'Инструменты',
            key: 'action',
            render: (_, rec) => (
                <Button onClick={(e) => handleDelete(e, rec)} type="primary" shape="circle" icon={<DeleteOutlined/>}/>
            ),
        },
    ];
    const onFinish = (values) => {
        values.items = sendData;
        axios.post(process.env.REACT_APP_URL + '/winstockadd', values, {
            headers: {
                'Authorization': authHeader(),
                'Content-type': 'application/json'
            },
        }).then(resData => {
            let result = resData.data
        })

    };
    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };
    const [open, setOpen] = useState(false);

    const showDrawer = () => {
        setOpen(true);
    };

    const onClose = () => {
        setOpen(false);
    };
    return (
        <>
            <div className={css.kirimhead}>
                <h2>Приход</h2>
                <Button onClick={showDrawer} type={'primary'}>Последные действии</Button>
                <Drawer size={'large'} placement='bottom' title="Oxirgi kirim qilingan Maxsulotlar" onClose={onClose} open={open}>
                    <Table
                        rowKey={'id'}
                        columns={c}
                        expandable={{
                            expandedRowRender: (record) => <Table rowKey={'id'} columns={inc} dataSource={record.items} />,
                            // rowExpandable: (record) => record.items.name !== 'Not Expandable',
                        }}
                        dataSource={hist}
                    />
                </Drawer>
            </div>
            <Row gutter={15}>
                <Col span={16}>
                    <Select
                        showSearch
                        size={'large'}
                        style={{width: '100%'}}
                        placeholder="Введите название марку или размерь"
                        optionFilterProp="children"
                        onChange={onChange}
                        onSearch={onSearch}
                        filterOption={(input, option) =>
                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                        }
                        options={data}
                    />
                    <Table rowKey={'warehouse_items_id'} columns={columns} dataSource={sendData}
                           style={{marginTop: '15px'}}/>
                </Col>
                <Col span={8}>
                    <Card>
                        <h5>Итог: {sendData.reduce((a, b) => a + b.summ, 0)}</h5>
                        <Form
                            labelCol={{
                                span: 24,
                            }}
                            wrapperCol={{
                                span: 24,
                            }}
                            layout="vertical"
                            initialValues={{status: "Принят"}}

                            onFinish={onFinish}
                            onFinishFailed={onFinishFailed}
                        >
                            <Row>
                                <Col>
                                    <Form.Item label="№ Фактуры" name="invoice_number">
                                        <Input placeholder={'№ Фактуры'}/>
                                    </Form.Item>
                                </Col>
                                <Col>
                                    <Form.Item label="Дата" name={'date'}>
                                        <Input type={'date'} />
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Form.Item label="Статус" name={'status'}>
                                <Select style={{width: '100%'}}>
                                    <Select.Option value="Qabul qilingan">Принят</Select.Option>
                                    <Select.Option value="Kutilmoqda">Ожидается</Select.Option>
                                    <Select.Option value="Bekor qilindi">Отменен</Select.Option>
                                </Select>
                            </Form.Item>

                            <Form.Item label="Поставщик" name="deliver_info">
                                <TextArea placeholder={'Поставщик'}/>
                            </Form.Item>

                            <Form.Item>
                                <Button type={"primary"} size={'large'} htmlType="submit">Принят</Button>
                            </Form.Item>
                        </Form>
                    </Card>
                </Col>
            </Row>
        </>
    )
}