import {Button, Col, Form, Input, message, Row, Select, Upload} from "antd";
import TextArea from "antd/lib/input/TextArea";
import {UploadOutlined} from "@ant-design/icons";
import {useEffect, useState} from "react";
import {useAuthHeader} from "react-auth-kit";
import axios from "axios";
import {InputNumber} from "antd/lib";

export function StockEdit({ iv, adder, rend }) {
    const [data, setData] = useState({});
    const [userImg, setUserImg] = useState(process.env.REACT_APP_MAINURL + '/upload/user/defuser.png');
    const authHeader = useAuthHeader()
    let url = process.env.REACT_APP_URL + '/stdata';

    useEffect(() => {
        axios(url, {
            method: 'GET',
            headers: {
                'Authorization': authHeader(),
                'Content-type': 'application/json'
            }
        }).then(data => {
            setData(data.data)
        })
    }, []);


    const props = {
        name: 'image',
        action: process.env.REACT_APP_URL + '/file',
        headers: {
            authorization: 'authorization-text',
        },
        onChange(info) {
            if (info.file.status !== 'uploading') {
                setUserImg(process.env.REACT_APP_MAINURL + info.file.response.data.image)
            }
            if (info.file.status === 'done') {
                message.success(`${info.file.name} file uploaded successfully`);
            } else if (info.file.status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
            }
        },
    };


    const onFinish = (values) => {
        values.img = userImg
        axios.post(process.env.REACT_APP_URL + '/witedit/' + values.id, values, {
            headers: {
                'Authorization': authHeader(),
                'Content-type': 'application/json'
            },
        }).then(resData => {
            let result = resData.data
            adder(!rend)
        })

    };
    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const [form] = Form.useForm()
    useEffect(() => {
        form.setFieldsValue(iv)
    }, [form, iv])

    return (
        <>
            <Form
                form={form}
                name="editform"
                initialValues={iv}
                labelCol={{
                    span: 24,
                }}
                wrapperCol={{
                    span: 24,
                }}
                layout="vertical"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
            >
                <fieldset>

                    <Form.Item hidden={true} name="id">
                        <InputNumber />
                    </Form.Item>
                    <Form.Item label="Название" name="name">
                        <Input placeholder={'Название'}/>
                    </Form.Item>
                    <Row>
                        <Col span={12}>
                            <Form.Item label="Марка" name="marka">
                                <Input placeholder={'Марка'}/>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="Размерь" name="razmer">
                                <Input placeholder={'Размерь'}/>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="Толшина" name="qalin">
                                <Input placeholder={'Толшина'}/>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="Категория" name='w_category_id'>
                                <Select>
                                    {data.categorys?.map(item => <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>)}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Form.Item label="Информация" name="desc">
                        <TextArea placeholder={'Информация'}/>
                    </Form.Item>
                </fieldset>

                <fieldset>

                    <Row>
                        <Col span={12}>
                            <Form.Item label="Остаток" name='stock'>
                                <Input placeholder='Остаток'/>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="Ед изм" name='units_id'>
                                <Select>
                                    {data.units?.map(item => <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>)}
                                </Select>
                            </Form.Item>
                        </Col>
                        {/*<Col span={12}>*/}
                        {/*    <Form.Item label="Цена" name='price'>*/}
                        {/*        <Input type="number" placeholder='Цена'/>*/}
                        {/*    </Form.Item>*/}

                        {/*</Col>*/}
                        <Col span={12}>
                            <Form.Item label="Цена в долларах" name='priceusd'>
                                <Input type="number" placeholder='Цена в долларах'/>
                            </Form.Item>
                        </Col>
                    </Row>
                </fieldset>

                <Form.Item label="Rasmni yuklash" name={'user_img'} valuePropName="user_img">
                    <Upload {...props}>
                        <Button type={'primary'} icon={<UploadOutlined/>}>Картинка</Button>
                    </Upload>
                </Form.Item>
                <hr/>
                <Form.Item>
                    <Button type={"primary"} size={'large'} htmlType="submit">Обновить</Button>
                </Form.Item>
            </Form>
        </>
    )
}