import {NavLink} from "react-router-dom";

export function Footer() {
    return (
        <>
            <footer className="footer">
                <div className="container">
                    <div className="footer-content__wrapper">
                        <div className="footer-content">
                            <img className="footer-hagen-logo"
                                 src="/assets/media/logo.svg"
                                 alt="logo"/>
                            <p className="footer-address">Ташкент, Урикзар. Гиламлар олами бозори 11А магазин </p>
                            <p className="footer-number__wrapper">
                                <a className="footer-number" href="tel:+998909116606">
                                    <i className="media-icon fa-sharp fa-solid fa-phone"></i>
                                    (+99890) 911-66-06
                                </a>
                                <a className="footer-number" href="tel:+998993046606">
                                    (+99899) 304-66-06
                                </a>
                            </p>
                            <a href="https://t.me/rimaxstelltrade" target="_blank" className="footer-telegram">
                                <i className="media-icon fa-brands fa-telegram"></i>
                                Telegram
                            </a>
                            <a href="mailto:rimax.steel.trade777@gmail.com" target="_blank" className="footer-gmail">
                                <i className="media-icon fa-regular fa-envelope"></i>
                                rimax.steel.trade777@gmail.com
                            </a>
                        </div>
                        <div className="footer-line"></div>
                        <div className="footer-logo__wrapper">
                        <NavLink to={'/login'}>
                            <img className="footer-logo"
                                 src="/assets/media/footer-logo.svg"
                                 alt="logo"/></NavLink>
                        </div>
                    </div>

                    <p className="copyright">© 2017 – 2025 ООО «HAGEN by RST». Все права защищены.</p>
                </div>
            </footer>
        </>
    )
}