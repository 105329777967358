import {NavLink} from "react-router-dom";
import {Header} from "./Header";
import {ProdCard} from "../../Components/ProdCard";
import {Footer} from "./Footer";
import {ProdModal} from "./ProdModal";
import {Intro} from "./Intro";
import {useEffect, useState} from "react";
import axios from "axios";
import {useAuthHeader} from "react-auth-kit";

export const Home = () => {
    let [pModal, setPModal] = useState(true)
    let [data, setData] = useState([])
    let [categorys, setCategorys] = useState([])
    let [modalData, setModalData] = useState({})
    let [selectedCat, setSelectedCat] = useState({id: 4, name: 'Отрезной диск'})
    let [val, setVal] = useState(false)
    let [kurs, setKurs] = useState(0)

    let url = process.env.REACT_APP_URL + '/witems';
    const authHeader = useAuthHeader();

    useEffect(() => {
        axios(url, {
            method: 'GET',
            headers: {
                'Authorization': authHeader(),
                'Content-type': 'application/json'
            }
        }).then(data => {
            setData(data.data)
        })
    }, []);

    useEffect(() => {
        axios(process.env.REACT_APP_URL + '/usdkurs', {
            method: 'GET',
            headers: {
                'Authorization': authHeader(),
                'Content-type': 'application/json'
            }
        }).then(data => {
            setKurs(data.data[0].Rate)
            console.log(data.data[0].Rate)
        })
    }, []);

    useEffect(() => {
        axios.get(process.env.REACT_APP_URL + '/wcategorys').then(data=> setCategorys(data.data))
    }, []);

    const handleProdModal = ()=>{
        setPModal(!pModal)
    }

    let products = data.filter(item => Number(item.w_category_id) === Number(selectedCat.id));

    return (
        <>
            <Header categorys={categorys} scat={setSelectedCat} val={val} setVal={setVal} />
            <Intro />
            <div className="layout__content">
                <main className="main">
                    <div className="container">
                        <div className="cards-block">
                            <div className="cards-title__block">
                                <h2 className="cards-title" style={{color: 'rgb(50, 61, 103)'}}>
                                    {selectedCat.name}
                                    <span></span>
                                </h2>
                                <span className="cards-title--line"></span>
                            </div>
                            <div className="cards-wrapper">
                                {products?.map(item=><ProdCard kurs={kurs} cat={selectedCat} val={val} key={item.id} mdata={setModalData} modal={handleProdModal} data={item} />)}
                            </div>
                        </div>
                    </div>
                </main>
            </div>
            {(pModal) ? '' : <ProdModal val={val} cat={selectedCat} kurs={kurs} mdata={modalData} close={handleProdModal}/>}

            <Footer/>

        </>
    )
}