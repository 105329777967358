import {Button, Form, Input, message} from "antd";
import TextArea from "antd/lib/input/TextArea";
import {useEffect} from "react";
import {useAuthHeader} from "react-auth-kit";
import axios from "axios";
import {InputNumber} from "antd/lib";

export function CategoryEdit({ iv, adder, rend }) {

    const authHeader = useAuthHeader()

    const onFinish = (values) => {
        axios.post(process.env.REACT_APP_URL + '/wcatedit/' + values.id, values, {
            headers: {
                'Authorization': authHeader(),
                'Content-type': 'application/json'
            },
        }).then(resData => {
            let result = resData.data
            console.log(result)
            message.success(`Ma'lumot muvaffaqiyatli yangilandi`);
            adder(!rend)
        })

    };
    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const [form] = Form.useForm()
    useEffect(() => {
        form.setFieldsValue(iv)
    }, [form, iv])
    return (
        <>
            <Form
                form={form}
                name="editform"
                initialValues={iv}
                labelCol={{
                    span: 24,
                }}
                wrapperCol={{
                    span: 24,
                }}
                layout="vertical"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
            >
                <fieldset>

                    <Form.Item hidden={true} name="id">
                        <InputNumber />
                    </Form.Item>
                    <Form.Item label="Название" name="name">
                        <Input placeholder={'Название'}/>
                    </Form.Item>
                    <Form.Item label="Описание" name="desc">
                        <TextArea placeholder={'Описание'}/>
                    </Form.Item>

                </fieldset>
                <hr/>
                <Form.Item>
                    <Button type={"primary"} size={'large'} htmlType="submit">Изменить</Button>
                </Form.Item>
            </Form>
        </>
    )
}