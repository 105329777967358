import {LoginLayout} from "./Layouts/LoginLayout/LoginLayout";
import {Route, Routes} from "react-router-dom";
import {RequireAuth} from "react-auth-kit";
import MainLayout from "./Layouts/MainLayout/MainLayout";
import {Home} from "./Layouts/Home/Home";


const App = () => {

    return (
        <>
            <Routes>
                <Route path={'/'} element={<Home/>}/>
                <Route path={'/login'} element={<LoginLayout/>}/>
                <Route path={'/sklad/*'} element={
                    <RequireAuth loginPath={'/login'}>
                        <MainLayout/>
                    </RequireAuth>
                }/>
            </Routes>
        </>
    )
};
export default App;
