import {useEffect, useState} from "react";

export function Header({categorys, scat, val, setVal}) {
    let [cataList, setCatList] = useState(true)
    let [menu, setMenu] = useState(true)


    useEffect(() => {
        window.addEventListener('scroll', isSticky);
        return () => {
            window.removeEventListener('scroll', isSticky);
        };
    });


    /* Method that will fix header after a specific scrollable */
    const isSticky = (e) => {
        const header = document.querySelector('header');
        const scrollTop = window.scrollY;
        scrollTop >= 250 ? header.classList.add('header--active') : header.classList.remove('header--active');
    };
    const handleCatalog = () => {
        setCatList(!cataList)
    }

    const handleMobMenu = () => {
        setMenu(!menu)
    }
    const handleChangeCat = (e, rec) => {
        e.preventDefault()
        scat(rec)
    }


    return (
        <>
            <header className="header">
                <div className="container">
                    <nav className="nav">
                        <img className="logo"
                             src="/assets/media/logo.svg"
                             alt="logo"/>
                        <ul className="nav-pages__list">
                            <li className="nav-pages__item"><a className="nav-pages__link" href="#">Главная</a></li>
                            <li className="nav-pages__item"><a className="nav-pages__link" href="#">О компании</a></li>
                            <li className="nav-pages__item"><a className="nav-pages__link" href="#foot">Контакты</a></li>
                        </ul>
                        <button className={'catalog-btn'} onClick={()=>setVal(!val)}>{(val)? 'USD' : 'UZS'}</button>
                        <div className="catalog-wrapper">
                            <button className="catalog-btn" onClick={handleCatalog}>
                                <div className="menu-bars">
                                    <div className="menu-bar bar-1"></div>
                                    <div className="menu-bar bar-2"></div>
                                    <div className="menu-bar bar-3"></div>
                                </div>
                                Каталог
                            </button>
                            <ul onMouseLeave={handleCatalog} className={(cataList) ? "nav-list" : "nav-list nav-list-active"}>
                                {categorys?.map(item => <li key={item.id} onClick={(e) => handleChangeCat(e, item)}
                                                            className="nav-item"><a className="nav-link"
                                                                                    href="/">{item.name}</a></li>)}
                            </ul>
                        </div>
                        <div onClick={handleMobMenu} className="mobile-menu-btn__wrapper">
                            <img src="/assets/media/menu-ico.svg" alt="menu-icon"/>
                        </div>
                    </nav>
                </div>
                <div className={(menu) ? "mobile-menu__wrapper" : "mobile-menu__wrapper menu-active"}>
                    <img src="/assets/media/close.svg"
                         style={{width: '20px', position: 'absolute', top: '30px', right: '30px'}}
                         onClick={() => setMenu(!menu)} alt=""/>
                    <ul className="mobile-menu__list">
                        <li className="mobile-menu__item"><a className="mobile-menu__link" href="#">Главная</a></li>
                        <li className="mobile-menu__item"><a className="mobile-menu__link" href="#">О компании</a></li>
                        <li className="mobile-menu__item"><a className="mobile-menu__link" href="#">Контакты</a></li>
                        {categorys?.map(item => <li onClick={(e) => {
                            handleChangeCat(e, item)
                            setMenu(!menu)
                        }} key={item.id}  className="mobile-menu__item"><span
                            className="mobile-menu__link"
                            href="/">{item.name}</span>
                        </li>)}
                    </ul>
                </div>
            </header>
        </>
    )
}