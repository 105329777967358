import css from './Login.module.css'
import {Button, Form, Input, message} from "antd";
import {useSignIn} from "react-auth-kit";
import {useNavigate} from "react-router-dom";
import axios from "axios";

export function LoginLayout() {
    const signIn = useSignIn()
    const navigate = useNavigate();
    const [messageApi, contextHolder] = message.useMessage();
    let url = process.env.REACT_APP_URL + '/login';

    const onFinish = (values) => {

        axios.post(url, values).then(data => {
            if (signIn(
                {
                    token: data.data.access_token,
                    expiresIn: data.data.expires_in,
                    tokenType: data.data.token_type,
                    authState: data.data.user,
                }
            )) {
                navigate("/sklad");
            } else {
                navigate("/login");
            }
        }).catch(data => {
            messageApi.open({
                type: 'error',
                content: data.message,
            });
        })
    };


    const onFinishFailed = (errorInfo) => {
        console.log("Failed:", errorInfo);
    };
    return (
        <div className={css.LoginLayout}>
            {contextHolder}
            <div className={css.formBlock}>
                <Form
                    name="basic"
                    labelCol={{
                        span: 24,
                    }}
                    wrapperCol={{
                        span: 24,
                    }}
                    style={{
                        maxWidth: 600,
                    }}
                    initialValues={{
                        remember: true,
                    }}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="on"
                >
                    <Form.Item
                        label="Email"
                        name="email"
                        rules={[
                            {
                                required: true,
                                message: "Iltimos Email manzilini kiriting",
                            },
                        ]}
                    >
                        <Input size={'large'}/>
                    </Form.Item>

                    <Form.Item
                        label="Пароль"
                        name="password"
                        rules={[
                            {
                                required: true,
                                message: "Iltimos parolni kiriting",
                            },
                        ]}
                    >
                        <Input.Password size={'large'}/>
                    </Form.Item>

                    <Form.Item
                        wrapperCol={{
                            offset: 8,
                            span: 16,
                        }}
                    >
                        <Button size={'large'} type="primary" htmlType="submit">
                            Вход
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        </div>
    )
}