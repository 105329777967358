import {useAuthHeader} from "react-auth-kit";
import {useEffect, useState} from "react";
import axios from "axios";
import {Button} from "antd";
import {Drawer, Table} from "antd/lib";
import {
    ArrowUpOutlined,
    ArrowDownOutlined,
    ArrowsAltOutlined,
} from '@ant-design/icons';

export function Valkurs() {
    let [data, setData] = useState([])
    let url = process.env.REACT_APP_URL + '/valkurs';
    const authHeader = useAuthHeader();
    const [open, setOpen] = useState(false);
    const showDrawer = () => {
        setOpen(true);
    };
    const onClose = () => {
        setOpen(false);
    };
    useEffect(() => {
        axios(url, {
            method: 'GET',
            headers: {
                'Authorization': authHeader(),
                'Content-type': 'application/json'
            }
        }).then(data => {
            setData(data.data)
        })
    }, []);
    let col = [
        {
            title: <ArrowsAltOutlined />,
            dataIndex: 'Diff',
            key: '',
            render: (_, item)=> (item.Diff > 0) ? <ArrowUpOutlined style={{color: 'blue'}} /> : <ArrowDownOutlined style={{color: 'red'}} />
        },
        {
            title: 'Valyuta nomi',
            dataIndex: 'CcyNm_UZ',
            key: 'CcyNm_UZ',
        },
        {
            title: 'So\'mga nisbatan kursi',
            dataIndex: 'Rate',
            key: 'Rate',
        },
        {
            title: 'O\'sish',
            dataIndex: 'Diff',
            key: 'Diff',
        },
    ]
    return (
        <>
            <Button type="primary" onClick={showDrawer}>
                Valyuta kurslari
            </Button>
            <Drawer size={'large'} title="Valyuta kurslari" placement="right" onClose={onClose} open={open}>
               <Table pagination={false} rowKey={'id'} columns={col} dataSource={data} />
            </Drawer>
        </>
    )
}