import { useState } from "react"

export function ProdCard({ modal, data, mdata, val, cat, kurs }) {
    // let [backcolor, setBackColor] = useState('#fff')


    const otr = () => {
        return (
            <>
                <p className="card-text card-mark">Марка: {data.marka}</p>
                <p className="card-text card-square">Размер: {data.razmer}</p>
            </>
        )
    }

    const nd = () => {
        return (
            <>
                <p className="card-text card-mark">Диаметр: {data.razmer}</p>
                <p className="card-text card-thickness">Толшина: {data.qalin}</p>
            </>
        )
    }

    const all = () => {
        return (
            <>
                <p className="card-text card-mark">Марка: {data.marka}</p>
                <p className="card-text card-square">Размер: {data.razmer}</p>
                <p className="card-text card-thickness">Толшина: {data.qalin}</p>
            </>
        )
    }
    return (
        <>
            <div onClick={() => {
                mdata(data)
                modal()
            }} className="card-item" style={{ backgroundColor: '#fff' }}>
                <img className="card-magnifying"
                    src="/assets/media/magnifying1.svg"
                    alt="magnifying" />
                <img className="card-img"
                    src={data.img}
                    alt="img" />
                <div className="card-text__block">
                    <p className="card-text card-title">{data.name}</p>
                    <p className="card-text card-manufacturer">HAGEN by<span> RST</span></p>
                    {(cat.id === 4) ? otr() : (cat.id === 1) ? '' : (cat.id === 5) ? nd() : all()}
                    <p style={{ color: 'red', fontWeight: 700 }} className="card-text card-thickness">В наличие: {data.stock} шт</p>
                    <p className="card-text card-price--sheets">{(val) ? Math.floor(data.priceusd * kurs) + ' сум' : data.priceusd + ' $'}</p>
                </div>
            </div>
        </>
    )
}